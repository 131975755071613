import Swiper from '../vendor/swiper.js';

// slider partners top
let heroSlider = new Swiper('.hero__slider', {
  observer: true,
  observeParents: true,
  spaceBetween: 30,
  slidesPerView: 'auto',
  speed: 8000,
  loop: true,
  autoplay: {
    delay: 0,
  },
});

// Reasons
let reasonsSlider = new Swiper('.reasons__slider', {
  slidesPerView: 'auto',

  observer: true,
  observeParents: true,
  loop: true,
  breakpoints: {
    320: {
      spaceBetween: 10,
    },
    768: {
      spaceBetween: 20,
    },
  },
});

// Feedbacks
let feedbacksSlider = new Swiper('.feedbacks__slider', {
  observer: true,
  observeParents: true,
  loop: true,
  navigation: {
    nextEl: ".feedback-next",
    prevEl: ".feedback-prev",
    clickable: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1.06,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 1.23,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 1.25,
      centeredSlides: true,
      spaceBetween: 20,
    },
    1240: {
      slidesPerView: 1.73,
      centeredSlides: true,
      spaceBetween: 20,
    },
  },
});

// Awards and Partners
let awardsSlider = new Swiper('.awards__slider', {
  observer: true,
  observeParents: true,
  loop: true,
  navigation: {
    nextEl: ".awards-next",
    prevEl: ".awards-prev",
    clickable: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 2,
      slidesPerColumn: 2,
      // centeredSlides: true,
      // slidesPerGroup: 4,
      // autoHeight: false,
      // watchOverflow: true,
      // grid: {
      //   rows: 2,
      // },
      spaceBetween: 0,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
});

// Recent Articles
let recentsSlider = new Swiper('.recents__slider', {
  slidesPerView: 2,
  spaceBetween: 20,
  observer: true,
  observeParents: true,
  loop: true,
  navigation: {
    nextEl: ".recents-next",
    prevEl: ".recents-prev",
    clickable: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
  },
});

// Blockchain
let blockchainSlider = new Swiper('.recents__slider--consultation', {
  slidesPerView: 2,
  spaceBetween: 10,
  observer: true,
  observeParents: true,
  // loop: true,
  navigation: {
    nextEl: ".recents-next",
    prevEl: ".recents-prev",
    clickable: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
  },
});

// Cases Slider
let casesSlider = new Swiper('.cases__slider', {
  observer: true,
  observeParents: true,
  loop: true,
  navigation: {
    nextEl: ".cases-next",
    prevEl: ".cases-prev",
    clickable: true,
  },
  breakpoints: {
    320: {
      slidesPerView: 1.1,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 2.55,
      spaceBetween: 10,
    },
    1025: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
  },
});

// Advisors Slider
for (const advisorsSlider of document.querySelectorAll('.advisors__slider')) {
  if (advisorsSlider) {
    (function () {
      "use strict";

      const breakpoint = window.matchMedia("(min-width:1025px)");
      let slider;

      const enableSwiper = function () {
        slider = new Swiper(advisorsSlider, {
          slidesPerView: 'auto',
          loop: true,
          freeMode: true,
          spaceBetween: 10,
          observer: true,
          observeParents: true,
          adaptiveHeight: true,
          navigation: {
            nextEl: ".advisors-next",
            prevEl: ".advisors-prev",
            clickable: true,
          },
        });
      };

      const breakpointChecker = function () {
        if (breakpoint.matches === true) {
          if (slider !== undefined) slider.destroy(true, true);
          return;
        } else if (breakpoint.matches === false) {
          return enableSwiper();
        }
      };

      breakpoint.addListener(breakpointChecker);
      breakpointChecker();
    })();
  }
}

// History Slider
// const historySlider = document.querySelector('.history__slider');

// if (historySlider) {
  const swiper = new Swiper('.history__slider', {
    slidesPerView: 'auto',
    // observer: true,
    // observeParents: true,
    mousewheel: true,
    slideToClickedSlide: true,
    speed: 400,
    navigation: {
      nextEl: '.history-next',
      prevEl: '.history-prev',
    },

    // breakpoints: {
    //   320: {
    //     speed: 500,
    //   },
    //   768: {
    //     speed: 1300,
    //   },
    // },
  });

// Outstaffing Slider
  const outstaffingSlider = new Swiper('.outstaffing-details__slider', {
    slidesPerView: 'auto',
    spaceBetween: 5,
    mousewheel: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.outstaffing-details-next',
      prevEl: '.outstaffing-details-prev',
    },

    breakpoints: {
      320: {
        // slidesPerView: 1.1,
        spaceBetween: 10,
      },
      768: {
        // slidesPerView: 2.25,
        spaceBetween: 10,
      },
      1025: {
        // slidesPerView: 3.5,
        spaceBetween: 10,
      },
    },
  });


const sliderMission = new Swiper('.mission__slider', {
  slidesPerView: 1.1,
  spaceBetween: 5,
  loop: true,
  centeredSlides: true,
  slideToClickedSlide: true,
  navigation: {
    nextEl: '.mission .mission-next',
    prevEl: '.mission .mission-prev',
  },
  effect: 'coverflow',
  coverflowEffect: {
    rotate: 0,
    stretch: 0,
    depth: 0,
    modifier: 3,
    slideShadows: false,
    
  },
  breakpoints: {
    768: {
      slidesPerView: 1.43,
      spaceBetween: 0,
      coverflowEffect: {
        stretch: '29.8%',
      },
    },
    1240: {
      slidesPerView: 1.75,
      spaceBetween: 0,
      coverflowEffect: {
        stretch: '27.1%',
      },
    },
  },
  on: {
    init() {
      updateClassesSliderMission(this);
    },
    slideChange() {
      updateClassesSliderMission(this);
    },
  },
});

function updateClassesSliderMission({ $el, slides, activeIndex }) {
  $el.find('.swiper-slide-prev-prev').removeClass('swiper-slide-prev-prev');
  slides.eq(activeIndex).prev().prev().addClass('swiper-slide-prev-prev');

  $el.find('.swiper-slide-next-next').removeClass('swiper-slide-next-next');
  slides.eq(activeIndex).next().next().addClass('swiper-slide-next-next');
}
