function inputFile() {
	let inputFile = document.querySelector('input[type=file]')
	if (inputFile) {
		let inputField = document.querySelector('.file')
		let fieldCv = document.querySelector('#cv')

		inputFile.addEventListener('change', function() {
			let fileName = inputField.querySelector('.file__name')

			if (fileName) {
				if (fieldCv) {
					fieldCv.value = this.files[0].name
					fieldCv.style.pointerEvents = 'none';
					inputField.classList.add('show')
				} else {
					fileName.innerText = this.files[0].name
					inputField.classList.add('show')
				}
				removeFilesItem()
			} else {
				inputField.classList.remove('show')
			}

		})

		function removeFilesItem(){
			let fileRemove = document.querySelector('.file__remove')
			
			fileRemove.onclick = function () {
				
				if (fieldCv) {
					fieldCv.value = ''
					fieldCv.style.pointerEvents = ''
					inputField.classList.remove('show')
					inputFile.value = ''
				} else {
					inputField.classList.remove('show')
					inputFile.value = ''
				}
			}
		}
	}
}

inputFile()