
document.addEventListener("DOMContentLoaded", (event) => {
	function vScrollInit() {
		let section = document.querySelector('.v-scroll')
		if (section) {
			gsap.registerPlugin(ScrollTrigger)

			let anim = gsap.timeline();
			let sectionWrap = document.querySelector('.v-scroll-wrap')
			let wrap = document.querySelector('.v-slider__wrapper')
			let el = document.querySelector('.v-slider')
			let offsetTop = 10
			let height = sectionWrap.scrollHeight
			let scrollHeight = height - sectionWrap.offsetHeight
			let sectionHeight = 0

			if (scrollHeight > 0) {
				sectionHeight = wrap.scrollHeight + 300
			} else {
				sectionHeight = wrap.scrollHeight
			}

			section.style = `--height: ${sectionHeight}px; --offsetTop: ${offsetTop}%`

			anim
				.fromTo(el,
					{
						y: 0,
					},
					{
						y: scrollHeight * -1,
					},
				);
			ScrollTrigger.create({
				animation: anim,
				trigger: wrap,
				start: `top ${offsetTop}%`,
				end: `bottom center`,
				scrub: true,
			});
		}
	}

	vScrollInit()

	window.addEventListener('resize', function(){
		ScrollTrigger.disable()
		ScrollTrigger.enable()
		vScrollInit()
	})
});