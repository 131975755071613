// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// let sections = gsap.utils.toArray(".history__timeline");
// let container = document.querySelector('.history__box');

// let scrollTween = gsap.to(sections, {
//   xPercent: -100 * (sections.length - 1),
//   ease: "none",
//   duration: 6,
//   scrollTrigger: {
//     trigger: ".scroll",
//     pin: true,
//     scrub: true,
//     end: () => `+=${container.offsetWidth}`
//   }
// });


