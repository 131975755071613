const opportunitiesCard = document.querySelectorAll('.opportunities__card')
const opportunitiesFilters = document.querySelectorAll('.opportunities__filter-tag')

if (document.querySelector('.opportunities__filter')) {
	document.querySelector('.opportunities__filter').addEventListener('click', event => {
		if (event.target.tagName !== 'LI') return false

		let filterAttr = event.target.getAttribute('data-filter');

		opportunitiesFilters.forEach(elem => {
			elem.classList.remove('active');
		})
		event.target.classList.add('active');

		opportunitiesCard.forEach(elem => {
			if (elem.getAttribute('data-filter-category') !== filterAttr && filterAttr !== 'all') {
				elem.classList.add('hide');
			} else {
				elem.classList.remove('hide');
			}
		})
	})
}