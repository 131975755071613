if (document.querySelector('#filter_search')) {
	document.querySelector('#filter_search').oninput = function () {
		let val = this.value.trim();
		let cardsTitles = document.querySelectorAll('.cards__item-title')

		if (val != ' ') {
			cardsTitles.forEach(elem => {
				if (elem.innerText.search(val) == -1) {
					elem.closest('.cards__item').classList.add('search-hide');
					elem.innerHTML = elem.innerText;
				}
				else {
					elem.closest('.cards__item').classList.remove('search-hide')
					let str = elem.innerText;
					elem.innerHTML = insertMark(str, elem.innerText.search(val), val.length)
				}
			})
		} else {
			cardsTitles.forEach(elem => {
				elem.closest('.cards__item').classList.remove('search-hide');
				elem.innerHTML = elem.innerText;
			})
		}
	}

	function insertMark(string, pos, len) {
		return string.slice(0, pos) + '<span class="cards__mark">' + string.slice(pos, pos + len) + '</span>' + string.slice(pos + len);
	}
}