const cards = document.querySelectorAll('.cards__item')
const cardsFilters = document.querySelectorAll('.cards__filter-tag li')

if (document.querySelector('.cards__filter-tag')) {
	document.querySelector('.cards__filter-tag').addEventListener('click', event => {
		if (event.target.tagName !== 'LI') return false
		
		let filterAttr = event.target.getAttribute('data-filter');

		cardsFilters.forEach(elem => {
			elem.classList.remove('active');
		})
		event.target.classList.add('active');

		cards.forEach(elem => {
			if (elem.getAttribute('data-filter-category') !== filterAttr && filterAttr !== 'all') {
				elem.classList.add('hide');
			} else {
				elem.classList.remove('hide');
			}
		})
	})
}