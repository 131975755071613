const form = document.querySelector('#connect-form');
if (!!form) {
	const formArr = Array.from(form);
	const validFormArr = [];
	const name = form.querySelector('#name');
	const email = form.querySelector('#email');
	const company = form.querySelector('#company');
	const tel = form.querySelector('#tel');

	formArr.forEach((el) => {
		if (el.hasAttribute("data-reg")) {
			el.setAttribute("is-valid", "0");
			validFormArr.push(el);
		}
	});

	form.addEventListener("input", inputHandler);
	form.addEventListener("submit", formCheck);

	function inputHandler({ target }) {
		if (target.hasAttribute("data-reg")) {
			inputCheck(target);
		}
	}

	function inputCheck(el) {
		const inputValue = el.value;
		const inputReg = el.getAttribute("data-reg");
		const reg = new RegExp(inputReg);

		if (reg.test(inputValue)) {
			el.setAttribute("is-valid", "1");
			el.style.borderBottom = "2px solid var(--done)";

			if (tel) {
				if (el.type === 'tel' && el.value.length < 5) {
					el.setAttribute("is-valid", "0");
					el.style.borderBottom = "2px solid var(--error)";
					form.querySelector('.error-tel').classList.add('show')
				} else {
					form.querySelector('.error-tel').classList.remove('show')
				}
			}

			if (el.name === 'name') {
				form.querySelector('.error-name').classList.remove('show')
				el.closest('.text-input').classList.add('done')
			}
			if (el.name === 'email') {
				form.querySelector('.error-email').classList.remove('show')
				el.closest('.text-input').classList.add('done')
			}
		} else {
			el.setAttribute("is-valid", "0");
			el.style.borderBottom = "2px solid var(--error)";
			if (el.name === 'name') {
				form.querySelector('.error-name').classList.add('show')
				el.closest('.text-input').classList.remove('done')
			}
			if (el.name === 'email') {
				form.querySelector('.error-email').classList.add('show')
				el.closest('.text-input').classList.remove('done')
			}
			if (el.name === 'tel') {
				form.querySelector('.error-tel').classList.add('show')
				el.closest('.text-input').classList.remove('done')
			}

		}
	}

	function formCheck(e) {
		e.preventDefault();
		const allValid = [];
		validFormArr.forEach((el) => {
			allValid.push(el.getAttribute("is-valid"));
		});

		const isAllValid = allValid.reduce((acc, current) => {
			return acc && current;
		});

		if (name.value.length < 2) {
			name.style.borderBottom = "2px solid var(--error)";
			form.querySelector('.error-name').classList.add('show')
		}
		if (email.value.length < 1) {
			email.style.borderBottom = "2px solid var(--error)";
			form.querySelector('.error-email').classList.add('show')
		}

		if (company) {
			if (company.value.length < 2) {
				company.style.borderBottom = "2px solid var(--error)";
				form.querySelector('.error-company').classList.add('show')
			} else {
				company.style.borderBottom = "2px solid var(--done)";
				company.closest('.text-input').classList.add('done')
				form.querySelector('.error-company').classList.remove('show')
			}
		}
		if (tel) {
			if (tel.value.length < 5) {
				tel.style.borderBottom = "2px solid var(--error)";
				form.querySelector('.error-tel').classList.add('show')
			} else {
				form.querySelector('.error-tel').classList.remove('show')
			}
		}

		let done = 0;
		allValid.forEach(el => {
			if (el == 1) done += 1
			if (validFormArr.length === done) formSubmit()
		})
	}

	async function formSubmit() {
		console.log('submint')
		// const data = serializeForm(form);
		// const response = await sendData(data);
		// if (response.ok) {
		// let done = form.querySelector('.form-sent')
		// done.classList.add('show')

		// setTimeout(() => {
		// 	done.classList.remove('show')
		// }, 6000);
		formReset();
		// }
	}

	// function serializeForm(formNode) {
	// 	return new FormData(form);
	// }

	// async function sendData(data) {
	// 	return await fetch("email.php", {
	// 		method: "POST",
	// 		body: data,
	// 	})
	// }

	function formReset() {
		validFormArr.forEach((el) => {
			el.setAttribute("is-valid", 0);
			el.value = ''
			form.querySelector('.form-error').classList.remove('show')
			if (company) form.querySelector('#company').value = ''
		});
		let arrInput = form.querySelectorAll('.text-input')
		arrInput.forEach(el => {
			el.classList.remove('done')
			let input = el.querySelector('input')
			if (input) input.style.borderBottom = "";
		})
	}
}
