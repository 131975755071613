const moreParent = document.querySelectorAll('[data-show-more]');

function showMoreParent (parent) {
  parent.forEach(function (item) {
    const showHeight = item.querySelector('[data-paragraphs]')
    const defaultShowParagraphs = showHeight.getAttribute('data-default-show-paragraphs');
    const paragraphs = item.querySelectorAll('p');
    const showMoreBtn = item.querySelector('[data-show-more-btn]');
    let showAllHeight = 0
    let currentHeight = 0
    let css = 0
    let gapHeight = 0

    window.onresize = setHeight

    function setHeight() {
      currentHeight = 0
      css = window.getComputedStyle(showHeight);
      showAllHeight = showHeight.scrollHeight

      gapHeight = parseFloat(css.gap.split('px')[0])

      currentHeight += gapHeight * (defaultShowParagraphs - 1)

      paragraphs.forEach(function (p, i) {
        if (i <= defaultShowParagraphs - 1) {
          currentHeight += p.clientHeight;
        }
      })

      showHeight.style.height = `${currentHeight}px`
    }
    setHeight()

    showMoreBtn.addEventListener('click', function(){
      let textShow = this.getAttribute('data-btn-show')
      let textHide = this.getAttribute('data-btn-hide')
      let textBtn = this.querySelector('p')

      if (this.classList.contains('active')) {
        this.classList.remove('active')
        textBtn.innerText = textShow
        showHeight.style.height = `${currentHeight}px`
      } else {
        this.classList.add('active')
        showHeight.style.height = `${showAllHeight}px`
        textBtn.innerText = textHide
      }
    })
    
  })

}

if(moreParent) {
  showMoreParent(moreParent);
};
