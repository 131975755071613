const filter = document.querySelectorAll('.projects-filter__item');

let selectScript = function (select) {

    select.forEach((item) => {
        const selectCurrent = item.querySelector(".projects-filter__current-text");
        
        item.addEventListener("click", (event) => {
            const el = event.target.dataset;
            const text = event.target.innerHTML;
            if (!!el.type || !!el.industry || !!el.technology) {
                selectCurrent.innerHTML = text;
                
                if (event.target.dataset.type) {
                    selectCurrent.setAttribute('data-type-selected', el.type);
                }
                if (event.target.dataset.industry) {
                    selectCurrent.setAttribute('data-industry-selected', el.industry);
                }
                if (event.target.dataset.technology) {
                    selectCurrent.setAttribute('data-technology-selected', el.technology);
                }
                applyFilter()
            }
            
            item.classList.toggle("active");
        });
        document.addEventListener("click", function (event) {
            if (!item.contains(event.target)) {
                item.classList.remove("active");
            }
        });
    });
}

if (filter) {
    selectScript(filter);
}

function applyFilter() {
    const filters = document.querySelectorAll('.projects-filter__current-text')
    const items = document.querySelectorAll('.cases__slide')

    items.forEach(c => c.classList.remove('hide'))

    filters.forEach(el => {
        if (el.dataset.typeSelected && el.dataset.typeSelected !== 'none') {
            items.forEach(card => {
                if (card.dataset.type !== el.dataset.typeSelected) card.classList.add('hide')
            })
        }
        if (el.dataset.industrySelected && el.dataset.industrySelected !== 'none') {
            items.forEach(card => {
                if (card.dataset.industry !== el.dataset.industrySelected) card.classList.add('hide')
            })
        }
        if (el.dataset.technologySelected && el.dataset.technologySelected !== 'none') {
            items.forEach(card => {
                if (card.dataset.technology !== el.dataset.technologySelected) card.classList.add('hide')
            })
        }
    })
    
}
